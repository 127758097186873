<template>
  <div class="section team-notification-section">
    <div class="section__header">
      <div class="section__wysiwyg wysiwyg">
        <h2>Team Notifications
          <ToolTip
            :type="'top'"
            :msg="'This section will coalesce all of your notifications from your home and work pages so that this section will consist of notifications only directly related to your immediate business, its growth, and management.'"
          />
        </h2>
      </div>
    </div>
    <div class="section__content">
      <div class="box overflow-content">
        <div class="box__list">
          <div v-for="(notification) in this.notifications.teamNotifications" :key='notification.index'
            class="box__item align_big-desktop-center team-notifications p_0" :data-id='notification.id'>

            <div class="box__item align_big-desktop-center">
              <div class="box__avatar box__avatar_quaternary">
                <img v-if="notification.author.avatar" :src="notification.author.avatar" alt="" />
                <img v-else src="../../assets/images/avatar-default.png" alt="Start-Up World" />
              </div>
              <div class="box__main direction_big-desktop-row">
                <div class="box__header">
                  <div class="wysiwyg wysiwyg_secondary mb_0">
                    <router-link :to="{name: 'profile', params: { id: notification.author.id}}">
                      <h3>{{notification.author.name}}</h3>
                    </router-link>
                    <ul>
                      <li v-if="notification.author.position">{{notification.author.position}}</li>
                      <li v-if="notification.author.company_name">
                        <router-link :to="{name: 'company_profile', params: { id: notification.author.company_id}}">
                          {{notification.author.company_name}}
                        </router-link>
                      </li>
                      <li v-if="notification.author.location">{{notification.author.location}}</li>
                    </ul>
                  </div>
                </div>
                <div class="btn-group">
                  <div class="btn-group__btn">
                    <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25ZM0.75 9C0.75 4.44365 4.44365 0.75 9 0.75C13.5563 0.75 17.25 4.44365 17.25 9C17.25 13.5563 13.5563 17.25 9 17.25C4.44365 17.25 0.75 13.5563 0.75 9Z" fill="#5D6167"></path>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M9 3.75C9.41421 3.75 9.75 4.08579 9.75 4.5V8.53647L12.3354 9.82918C12.7059 10.0144 12.8561 10.4649 12.6708 10.8354C12.4856 11.2059 12.0351 11.3561 11.6646 11.1708L8.66459 9.67082C8.4105 9.54378 8.25 9.28408 8.25 9V4.5C8.25 4.08579 8.58579 3.75 9 3.75Z" fill="#5D6167"></path>
                    </svg>
                    <span class="btn__text">{{notification.date}}</span>
                  </div>
                </div>

                <div v-if="['CalendarEvent','CompanyRole'].includes(notification.notify_type)" class="wysiwyg">
                    <a href="#" @click.prevent="showEvent(notification.id)">{{notification.message}}</a>
                </div>
                <div v-if="['WorkPageUpdate'].includes(notification.notify_type)" class="wysiwyg">
                    <router-link @click.prevent="goToPost(notification)" :to="{name: 'post', params: {id: notification.params.post_id}}">{{notification.message}}</router-link>
                </div>

              </div>

              <div v-if="notification.notify_type == 'CompanyJoinInvite'" class="box__request request ml-big-desktop-auto">
                <div class="request__title">
                  <span v-if="notification.need_confirm == 1" class="title-text">{{notification.message}}</span>
                  <span v-else @click.prevent="setViewed(notification.id)" class="title-text">{{notification.message}}</span>
                </div>
                <div v-if="notification.need_confirm == 1" class="btn-group btn-group_secondary content-right">
                  <div class="dropdown dropdown_secondary-content">
                    <div class="dropdown__init">
                      <span @click.prevent="rejectInvite(notification.id)" class="btn btn_tiny-p btn_error">Reject</span>
                    </div>
                  </div>
                  <div class="dropdown dropdown_secondary-content">
                    <div class="dropdown__init">
                      <span @click.prevent="acceptInvite(notification.id)" class="btn btn_tiny-p btn_success">Allow</span>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="notification.notify_type == 'WorkPageInvite'" class="box__request request ml-big-desktop-auto">
                <div class="request__title">
                  <span class="title-text">{{notification.message}}</span>
                </div>
                <div v-if="notification.need_confirm == 1" class="btn-group btn-group_secondary content-right">
                  <div class="dropdown dropdown_secondary-content">
                    <div class="dropdown__init">
                      <span @click.prevent="rejectInviteWorkPage(notification.id)" class="btn btn_tiny-p btn_error">Reject</span>
                    </div>
                  </div>
                  <div class="dropdown dropdown_secondary-content">
                    <div class="dropdown__init">
                      <span @click.prevent="acceptInviteWorkPage(notification.id, notification.params)" class="btn btn_tiny-p btn_success">Allow</span>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { router } from '@/router'
import ToolTip from '@/components/ToolTip'

export default {
  name: "TeamNotifications",
  components: {
    ToolTip,
  },
  data() {
    return {
    }
  },
  beforeMount() {
    this.getNotifications();
  },
  mounted() {
    //   this.loadmore();
  },
  updated() {
    //   this.loadmore();
  },
  methods: {
    loadmore() {
      console.log('loadmore');
      var container = this.$el.querySelector(".section__content");
      // console.log(document.getElementsByClassName('team-notifications'));
      const el = document.getElementsByClassName('team-notifications');
      for (const key in el) {
        if(key < el.length  && el[key].classList.contains('notifications-viewed')) {
          continue;
        }
        // if el[key] in container add class 'notifications-viewed'
        if(el[key].offsetTop <= container.scrollTop + container.offsetHeight + 50) {
          setTimeout(function(){
            el[key].classList.add('notifications-viewed');
          }, 1000);
          // let dataID = el[key].getAttribute('data-id');
          // console.log('Send viewed to: '+dataID);
        }
      }


      container.onscroll = () => {
        const el = document.getElementsByClassName('team-notifications');
        for (const key in el) {
          if(key < el.length  && el[key].classList.contains('notifications-viewed')) {
            continue;
          }
          // if el[key] in container add class 'notifications-viewed'
          if(el[key].offsetTop <= container.scrollTop + container.offsetHeight + 50) {
            el[key].classList.add('notifications-viewed');
            // let dataID = el[key].getAttribute('data-id');
            // console.log('Send viewed to: '+dataID);
          }
        }
      }
    },
    getNotifications: function() {
      store.dispatch('GET_NOTIFICATIONS_TEAM');
    },
    rejectInvite(id) {
      store.dispatch('REJECT_INVITE', {id: id});
      this.setViewed(id);
    },
    acceptInvite(id) {
      store.dispatch('ACCEPT_INVITE', {id: id});
      this.setViewed(id);
    },
    async showEvent(id) {
      await store.dispatch('SET_PREPARE_VIEW_EVENT', id)
      this.setViewed(id);
      router.push({name: 'crm'});
    },

    rejectInviteWorkPage(id) {
      store.dispatch('REJECT_INVITE_WORK_PAGE', {id: id});
      this.setViewed(id);
    },
    async acceptInviteWorkPage(id, params) {
      await store.dispatch('ACCEPT_INVITE_WORK_PAGE', {id: id});
      router.push({name: 'page', params: { id: params.page_id }});
    },

    goToPost(note) {
      store.commit('SET_COMMENT_TO_HIGHLIGHT', note.params.comment_id);
      this.setViewed(note.id);
      this.$router.push({name: "post", params: {id: note.params.post_id}})
    },

    setViewed(id) {
      store.dispatch('SET_NOTIFICATION_VIEWED', {id: id, type: 'team'});
    },

  },
  computed: {
    crm: () => store.state.crm,
    notifications: () => store.state.notifications,
    calendar: () => store.state.calendar,
  }
}
</script>
<style>
.box .box__item {
  width: 100%;
}
.box .box__item .box__request{
  max-width: 450px;
}
.date_event_custom {
  align-items: flex-start !important;
}
</style>