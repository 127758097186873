<template>
    <main-layout>
        <div class="main">
            <div class="main__header header">
                <div class="header__wysiwyg wysiwyg">
                    <h2>Notifications
                        <ToolTip
                            :type="'top'"
                            :msg="'This section will be used to coalesce and organize all of the notifications you are receiving through the platform, from team notifications relating to your business, platform notifications to get updates from your community forums, and more, it will all be conveniently organized here. In order to remove notifications you will have to click through the notification via the notification page.<br><br>You can also have notifications emailed to you by heading over to your settings section, click onEmail Notifications?<br><br>Next you put the email address you want the notifications to be emailed to, and finally you select the types of notifications you want emailed. Head to the bottom of the settings section on the right and click save.'"
                        />
                    </h2>
                </div>
            </div>

            <!-- main-content -->
            <div class="main__content">
                <div class="entrepreneur">

                    <TeamNotifications></TeamNotifications>

                    <ProjectNotifications></ProjectNotifications>

                    <NotificationBox></NotificationBox>

                    <ManagersBox
                        v-if="['administrator', 'community_manager'].includes(userdata.user_profile.in_company_role)"
                    ></ManagersBox>
                    <FullMessagesViewPopup></FullMessagesViewPopup>
                </div>
            </div>
        </div>
        <ViewfilePopup></ViewfilePopup>
    </main-layout>
</template>

<script>
    import MainLayout from "../layouts/Main.vue"
    import store from '../store'
    import TeamNotifications from '@/components/notification/TeamNotifications'
    import ProjectNotifications from '@/components/notification/ProjectNotifications'
    import NotificationBox from '@/components/notification/Notifications'
    import ManagersBox from '@/components/notification/ManagersBox'
    import FullMessagesViewPopup from '@/components/popups/FullMessagesView'
    import ViewfilePopup from '@/components/popups/Viewfile'
    import ToolTip from '@/components/ToolTip'


    export default {
        name: "Notification",
        components: {
            MainLayout,
            TeamNotifications,
            ProjectNotifications,
            NotificationBox,
            ManagersBox,
            FullMessagesViewPopup,
            ViewfilePopup,
            ToolTip,
        },
        mounted() {
            // this.getLoaded();
        },
        methods: {
            getLoaded: function() {
                jcf.setOptions('Select', {
                    wrapNative: false,
                    useCustomScroll: false,
                    fakeDropInBody: false,
                    multipleCompactStyle: true
                });
                jcf.replaceAll();
            }
        },
        computed: {
            company: () => store.state.company,
            userdata: () => store.state.user,
            popups: () => store.state.popups,
        }
    }
</script>
