<template>
  <div class="section">
    <div class="section__header">
      <div class="section__wysiwyg wysiwyg">
        <h2>
          Platform Notifications
          <ToolTip
            :type="'top mw-460'"
            :msg="'This Section will coalesce all of your notifications from everywhere on the platform not directly related to your business growth and management such as:<br><br>Notifications of updates from Community Forums that you follow<br>Notifications of comments, replies, likes to your posts<br>Notifications of members not on your team who sent you a message through the platform<br>Notifications regarding your requests for new forums<br>etc.'"
          />
        </h2>
      </div>
    </div>
    <div class="section__content">

      <!-- Forum updates -->
      <div  v-for="(forum_update, indexFU) in this.notifications.personalNotifications.forum_updates" :key='indexFU' class="accordion">
        <div class="accordion__list">
          <div v-show="forum_update.data.length > 0" :class="{'accordion-item_active': forum_update.openAccordion}" class="accordion__item accordion-item">
            <div @click="forum_update.openAccordion = !forum_update.openAccordion" class="accordion-item__header">
              <div class="accordion-item__ico">
                <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M1 3C1.55228 3 2 3.44772 2 4V9H7C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11H1C0.447715 11 0 10.5523 0 10V4C0 3.44772 0.447715 3 1 3Z" fill="white"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M16 14C16 13.4477 16.4477 13 17 13H23C23.5523 13 24 13.4477 24 14V20C24 20.5523 23.5523 21 23 21C22.4477 21 22 20.5523 22 20V15H17C16.4477 15 16 14.5523 16 14Z" fill="white"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.22695 2.39574C10.8497 1.92637 12.5648 1.87571 14.2124 2.2485C15.86 2.62129 17.3863 3.40536 18.6489 4.52757C19.9115 5.64977 20.8692 7.07353 21.4327 8.66599C21.617 9.18664 21.3442 9.75806 20.8236 9.9423C20.303 10.1265 19.7315 9.85383 19.5473 9.33318C19.0965 8.05921 18.3303 6.92021 17.3202 6.02244C16.3101 5.12468 15.0891 4.49742 13.771 4.19919C12.453 3.90096 11.0808 3.94149 9.78267 4.31698C8.48451 4.69248 7.30262 5.39071 6.34729 6.34652C6.3399 6.35391 6.3324 6.36118 6.32478 6.36834L1.68478 10.7283C1.2823 11.1065 0.649442 11.0868 0.27125 10.6844C-0.106942 10.2819 -0.0872528 9.64903 0.315226 9.27083L4.9441 4.92129C6.13627 3.73236 7.6094 2.86362 9.22695 2.39574ZM23.7288 13.3148C24.1069 13.7173 24.0873 14.3501 23.6848 14.7283L19.0559 19.0779C17.8637 20.2668 16.3906 21.1356 14.7731 21.6034C13.1503 22.0728 11.4352 22.1235 9.78761 21.7507C8.14003 21.3779 6.61373 20.5938 5.35114 19.4716C4.08855 18.3494 3.13081 16.9256 2.56729 15.3332C2.38305 14.8125 2.65576 14.2411 3.17641 14.0569C3.69705 13.8726 4.26848 14.1453 4.45272 14.666C4.90353 15.94 5.66973 17.079 6.6798 17.9767C7.68988 18.8745 8.91091 19.5018 10.229 19.8C11.547 20.0982 12.9192 20.0577 14.2173 19.6822C15.5155 19.3067 16.6974 18.6085 17.6527 17.6527C17.6572 17.6482 17.6616 17.6438 17.6662 17.6394C17.6692 17.6366 17.6722 17.6337 17.6752 17.6308L22.3152 13.2708C22.7177 12.8926 23.3506 12.9123 23.7288 13.3148Z" fill="white"/>
                </svg>
              </div>
              <div class="accordion-item__title">
                <span class="title-text">{{forum_update.data.filter(o => o.state == 1).length > 0 ? forum_update.data.filter(o => o.state == 1).length : 'No'}} updates in
                  <router-link :to="{name: 'forumTopic', params: {id: forum_update.page_id}}">{{forum_update.title}}</router-link>
                </span>
              </div>
              <div class="accordion-item__toggle">
                <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z" fill="#5D6167"/>
                </svg>
              </div>
            </div>
            <div class="accordion-item__body">
              <div class="box overflow-content">
                <div class="box__list">
                  <div  v-for="(fu_notification) in forum_update.data" :key='fu_notification.index'
                  class="box__item align_big-desktop-center">
                  <div class="box__avatar box__avatar_quaternary">
                    <img v-if="fu_notification.author.avatar" :src="fu_notification.author.avatar" alt="" />
                    <img v-else src="../../assets/images/avatar-default.png" alt="Start-Up World" />
                  </div>
                  <div class="box__main direction_big-desktop-row">
                    <div class="box__header">
                      <div class="wysiwyg wysiwyg_secondary mb_0">
                        <router-link :to="{name: 'profile', params: { id: fu_notification.author.id}}">
                          <h3>{{fu_notification.author.name}}</h3>
                        </router-link>
                        <ul>
                          <li v-if="fu_notification.author.position">{{fu_notification.author.position}}</li>
                          <li v-if="fu_notification.author.company_name">
                            <router-link :to="{name: 'company_profile', params: { id: fu_notification.author.company_id}}">
                              {{fu_notification.author.company_name}}
                            </router-link>
                          </li>
                          <li v-if="fu_notification.author.location">{{fu_notification.author.location}}</li>
                        </ul>
                      </div>
                    </div>
                    <div class="btn-group">
                      <div class="btn-group__btn">
                        <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25ZM0.75 9C0.75 4.44365 4.44365 0.75 9 0.75C13.5563 0.75 17.25 4.44365 17.25 9C17.25 13.5563 13.5563 17.25 9 17.25C4.44365 17.25 0.75 13.5563 0.75 9Z" fill="#5D6167"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M9 3.75C9.41421 3.75 9.75 4.08579 9.75 4.5V8.53647L12.3354 9.82918C12.7059 10.0144 12.8561 10.4649 12.6708 10.8354C12.4856 11.2059 12.0351 11.3561 11.6646 11.1708L8.66459 9.67082C8.4105 9.54378 8.25 9.28408 8.25 9V4.5C8.25 4.08579 8.58579 3.75 9 3.75Z" fill="#5D6167"/>
                        </svg>
                        <span class="btn__text">{{fu_notification.date}}</span>
                      </div>
                    </div>
                    <div class="wysiwyg">
                      <p>
                        <router-link @click.prevent="goToTopic(fu_notification)" :to="{name: 'post', params: {id: fu_notification.params.post_id}}">
                          <strong v-if="fu_notification.state == 1">{{fu_notification.message}}</strong>
                          <span v-else>{{fu_notification.message}}</span>
                        </router-link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- response new Forum topics -->
    <div class="accordion">
      <div class="accordion__list">


        <div v-show="this.notifications.personalNotifications.forum_response.data?.length > 0" :class="{'accordion-item_active': accordion.forumResponse}" class="accordion__item accordion-item">
          <div @click="accordion.forumResponse = !accordion.forumResponse" class="accordion-item__header">
            <div class="accordion-item__ico">
              <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1 3C1.55228 3 2 3.44772 2 4V9H7C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11H1C0.447715 11 0 10.5523 0 10V4C0 3.44772 0.447715 3 1 3Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16 14C16 13.4477 16.4477 13 17 13H23C23.5523 13 24 13.4477 24 14V20C24 20.5523 23.5523 21 23 21C22.4477 21 22 20.5523 22 20V15H17C16.4477 15 16 14.5523 16 14Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.22695 2.39574C10.8497 1.92637 12.5648 1.87571 14.2124 2.2485C15.86 2.62129 17.3863 3.40536 18.6489 4.52757C19.9115 5.64977 20.8692 7.07353 21.4327 8.66599C21.617 9.18664 21.3442 9.75806 20.8236 9.9423C20.303 10.1265 19.7315 9.85383 19.5473 9.33318C19.0965 8.05921 18.3303 6.92021 17.3202 6.02244C16.3101 5.12468 15.0891 4.49742 13.771 4.19919C12.453 3.90096 11.0808 3.94149 9.78267 4.31698C8.48451 4.69248 7.30262 5.39071 6.34729 6.34652C6.3399 6.35391 6.3324 6.36118 6.32478 6.36834L1.68478 10.7283C1.2823 11.1065 0.649442 11.0868 0.27125 10.6844C-0.106942 10.2819 -0.0872528 9.64903 0.315226 9.27083L4.9441 4.92129C6.13627 3.73236 7.6094 2.86362 9.22695 2.39574ZM23.7288 13.3148C24.1069 13.7173 24.0873 14.3501 23.6848 14.7283L19.0559 19.0779C17.8637 20.2668 16.3906 21.1356 14.7731 21.6034C13.1503 22.0728 11.4352 22.1235 9.78761 21.7507C8.14003 21.3779 6.61373 20.5938 5.35114 19.4716C4.08855 18.3494 3.13081 16.9256 2.56729 15.3332C2.38305 14.8125 2.65576 14.2411 3.17641 14.0569C3.69705 13.8726 4.26848 14.1453 4.45272 14.666C4.90353 15.94 5.66973 17.079 6.6798 17.9767C7.68988 18.8745 8.91091 19.5018 10.229 19.8C11.547 20.0982 12.9192 20.0577 14.2173 19.6822C15.5155 19.3067 16.6974 18.6085 17.6527 17.6527C17.6572 17.6482 17.6616 17.6438 17.6662 17.6394C17.6692 17.6366 17.6722 17.6337 17.6752 17.6308L22.3152 13.2708C22.7177 12.8926 23.3506 12.9123 23.7288 13.3148Z" fill="white"/>
              </svg>
            </div>
            <div class="accordion-item__title">
              <span class="title-text">{{this.notifications.personalNotifications.forum_response.data.length}} responses in requests for create new forum topics</span>
            </div>
            <div class="accordion-item__toggle">
              <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z" fill="#5D6167"/>
              </svg>
            </div>
          </div>
          <div class="accordion-item__body">
            <div class="box overflow-content">
              <div class="box__list">

                <div v-for="(notification) in this.notifications.personalNotifications.forum_response.data" :key='notification.index' class="box__item align_big-desktop-center">
                  <div class="box__main direction_big-desktop-row">
                    <div class="btn-group">
                      <div class="btn-group__btn">
                        <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25ZM0.75 9C0.75 4.44365 4.44365 0.75 9 0.75C13.5563 0.75 17.25 4.44365 17.25 9C17.25 13.5563 13.5563 17.25 9 17.25C4.44365 17.25 0.75 13.5563 0.75 9Z" fill="#5D6167"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M9 3.75C9.41421 3.75 9.75 4.08579 9.75 4.5V8.53647L12.3354 9.82918C12.7059 10.0144 12.8561 10.4649 12.6708 10.8354C12.4856 11.2059 12.0351 11.3561 11.6646 11.1708L8.66459 9.67082C8.4105 9.54378 8.25 9.28408 8.25 9V4.5C8.25 4.08579 8.58579 3.75 9 3.75Z" fill="#5D6167"/>
                        </svg>
                        <span class="btn__text">{{notification.date}}</span>
                      </div>
                    </div>
                    <div @click="setViewed(notification)" class="wysiwyg">
                      <p v-if="notification.params.type == 'success'">
                        {{notification.params.msg_start}}
                        <router-link :to="{name: 'forumTopic', params: {id:notification.params.topic_id}}">{{notification.params.topic_title}}.</router-link>
                        {{notification.params.msg_end}}
                      </p>
                      <p v-else>{{notification.message}}</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>


    <!-- access to media -->
    <div class="accordion">
      <div class="accordion__list">


        <div v-if="this.notifications.personalNotifications.access_notify.data?.length > 0" :class="{'accordion-item_active': accordion.accessResponse}" class="accordion__item accordion-item">
          <div @click="accordion.accessResponse = !accordion.accessResponse" class="accordion-item__header">
            <div class="accordion-item__ico">
              <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1 3C1.55228 3 2 3.44772 2 4V9H7C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11H1C0.447715 11 0 10.5523 0 10V4C0 3.44772 0.447715 3 1 3Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16 14C16 13.4477 16.4477 13 17 13H23C23.5523 13 24 13.4477 24 14V20C24 20.5523 23.5523 21 23 21C22.4477 21 22 20.5523 22 20V15H17C16.4477 15 16 14.5523 16 14Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.22695 2.39574C10.8497 1.92637 12.5648 1.87571 14.2124 2.2485C15.86 2.62129 17.3863 3.40536 18.6489 4.52757C19.9115 5.64977 20.8692 7.07353 21.4327 8.66599C21.617 9.18664 21.3442 9.75806 20.8236 9.9423C20.303 10.1265 19.7315 9.85383 19.5473 9.33318C19.0965 8.05921 18.3303 6.92021 17.3202 6.02244C16.3101 5.12468 15.0891 4.49742 13.771 4.19919C12.453 3.90096 11.0808 3.94149 9.78267 4.31698C8.48451 4.69248 7.30262 5.39071 6.34729 6.34652C6.3399 6.35391 6.3324 6.36118 6.32478 6.36834L1.68478 10.7283C1.2823 11.1065 0.649442 11.0868 0.27125 10.6844C-0.106942 10.2819 -0.0872528 9.64903 0.315226 9.27083L4.9441 4.92129C6.13627 3.73236 7.6094 2.86362 9.22695 2.39574ZM23.7288 13.3148C24.1069 13.7173 24.0873 14.3501 23.6848 14.7283L19.0559 19.0779C17.8637 20.2668 16.3906 21.1356 14.7731 21.6034C13.1503 22.0728 11.4352 22.1235 9.78761 21.7507C8.14003 21.3779 6.61373 20.5938 5.35114 19.4716C4.08855 18.3494 3.13081 16.9256 2.56729 15.3332C2.38305 14.8125 2.65576 14.2411 3.17641 14.0569C3.69705 13.8726 4.26848 14.1453 4.45272 14.666C4.90353 15.94 5.66973 17.079 6.6798 17.9767C7.68988 18.8745 8.91091 19.5018 10.229 19.8C11.547 20.0982 12.9192 20.0577 14.2173 19.6822C15.5155 19.3067 16.6974 18.6085 17.6527 17.6527C17.6572 17.6482 17.6616 17.6438 17.6662 17.6394C17.6692 17.6366 17.6722 17.6337 17.6752 17.6308L22.3152 13.2708C22.7177 12.8926 23.3506 12.9123 23.7288 13.3148Z" fill="white"/>
              </svg>
            </div>
            <div class="accordion-item__title">
              <span class="title-text">{{this.notifications.personalNotifications.access_notify.data.length}} responses on accses to media</span>
            </div>
            <div class="accordion-item__toggle">
              <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z" fill="#5D6167"/>
              </svg>
            </div>
          </div>
          <div class="accordion-item__body">
            <div class="box overflow-content">

              <div class="box__list">
                <div  v-for="(notification) in this.notifications.personalNotifications.access_notify.data" :key='notification.index'
                class="box__item align_big-desktop-center">
                <div class="box__avatar box__avatar_quaternary">
                  <img v-if="notification.author.avatar" :src="notification.author.avatar" alt="" />
                  <img v-else src="../../assets/images/avatar-default.png" alt="Start-Up World" />
                </div>
                <div class="box__main direction_big-desktop-row">
                  <div class="box__header">
                    <div class="wysiwyg wysiwyg_secondary mb_0">
                      <router-link :to="{name: 'profile', params: { id: notification.author.id}}">
                        <h3>{{notification.author.name}}</h3>
                      </router-link>
                      <ul>
                        <li v-if="notification.author.position">{{notification.author.position}}</li>
                        <li v-if="notification.author.company_name">
                          <router-link :to="{name: 'company_profile', params: { id: notification.author.company_id}}">
                            {{notification.author.company_name}}
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="btn-group">
                    <div class="btn-group__btn">
                      <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25ZM0.75 9C0.75 4.44365 4.44365 0.75 9 0.75C13.5563 0.75 17.25 4.44365 17.25 9C17.25 13.5563 13.5563 17.25 9 17.25C4.44365 17.25 0.75 13.5563 0.75 9Z" fill="#5D6167"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 3.75C9.41421 3.75 9.75 4.08579 9.75 4.5V8.53647L12.3354 9.82918C12.7059 10.0144 12.8561 10.4649 12.6708 10.8354C12.4856 11.2059 12.0351 11.3561 11.6646 11.1708L8.66459 9.67082C8.4105 9.54378 8.25 9.28408 8.25 9V4.5C8.25 4.08579 8.58579 3.75 9 3.75Z" fill="#5D6167"/>
                      </svg>
                      <span class="btn__text">{{notification.date}}</span>
                    </div>
                  </div>
                  <!-- <div @click="setViewed(notification)" class="wysiwyg">
                    <p>
                      {{notification.message}}
                    </p>
                  </div> -->
                </div>
                <div @click="setViewed(notification)" class="box__request request ml-big-desktop-auto pointer">
                  <div class="request__title">
                    <span class="title-text">{{notification.message}}</span>
                  </div>
                </div>
              </div>

            </div>

          </div>


        </div>
      </div>

    </div>
  </div>

  <!-- messages notification -->
  <div class="accordion">
    <div class="accordion__list">

      <div v-if="this.notifications.personalNotifications.messages.data?.length > 0" :class="{'accordion-item_active': accordion.messages}" class="accordion__item accordion-item">
        <div @click="accordion.messages = !accordion.messages" class="accordion-item__header">
          <div class="accordion-item__ico">
            <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M1 3C1.55228 3 2 3.44772 2 4V9H7C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11H1C0.447715 11 0 10.5523 0 10V4C0 3.44772 0.447715 3 1 3Z" fill="white"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M16 14C16 13.4477 16.4477 13 17 13H23C23.5523 13 24 13.4477 24 14V20C24 20.5523 23.5523 21 23 21C22.4477 21 22 20.5523 22 20V15H17C16.4477 15 16 14.5523 16 14Z" fill="white"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.22695 2.39574C10.8497 1.92637 12.5648 1.87571 14.2124 2.2485C15.86 2.62129 17.3863 3.40536 18.6489 4.52757C19.9115 5.64977 20.8692 7.07353 21.4327 8.66599C21.617 9.18664 21.3442 9.75806 20.8236 9.9423C20.303 10.1265 19.7315 9.85383 19.5473 9.33318C19.0965 8.05921 18.3303 6.92021 17.3202 6.02244C16.3101 5.12468 15.0891 4.49742 13.771 4.19919C12.453 3.90096 11.0808 3.94149 9.78267 4.31698C8.48451 4.69248 7.30262 5.39071 6.34729 6.34652C6.3399 6.35391 6.3324 6.36118 6.32478 6.36834L1.68478 10.7283C1.2823 11.1065 0.649442 11.0868 0.27125 10.6844C-0.106942 10.2819 -0.0872528 9.64903 0.315226 9.27083L4.9441 4.92129C6.13627 3.73236 7.6094 2.86362 9.22695 2.39574ZM23.7288 13.3148C24.1069 13.7173 24.0873 14.3501 23.6848 14.7283L19.0559 19.0779C17.8637 20.2668 16.3906 21.1356 14.7731 21.6034C13.1503 22.0728 11.4352 22.1235 9.78761 21.7507C8.14003 21.3779 6.61373 20.5938 5.35114 19.4716C4.08855 18.3494 3.13081 16.9256 2.56729 15.3332C2.38305 14.8125 2.65576 14.2411 3.17641 14.0569C3.69705 13.8726 4.26848 14.1453 4.45272 14.666C4.90353 15.94 5.66973 17.079 6.6798 17.9767C7.68988 18.8745 8.91091 19.5018 10.229 19.8C11.547 20.0982 12.9192 20.0577 14.2173 19.6822C15.5155 19.3067 16.6974 18.6085 17.6527 17.6527C17.6572 17.6482 17.6616 17.6438 17.6662 17.6394C17.6692 17.6366 17.6722 17.6337 17.6752 17.6308L22.3152 13.2708C22.7177 12.8926 23.3506 12.9123 23.7288 13.3148Z" fill="white"/>
            </svg>
          </div>
          <div class="accordion-item__title">
            <span class="title-text">
              {{notifications.personalNotifications.messages.data.filter(o => o.params.conversation.unread > 0).length > 0
                ? notifications.personalNotifications.messages.data.filter(o => o.params.conversation.unread > 0).length
                : 'No'}} new messages
              </span>
            </div>
            <div class="accordion-item__toggle">
              <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z" fill="#5D6167"/>
              </svg>
            </div>
          </div>
          <div class="accordion-item__body">
            <div class="box">
              <div class="box__list">
                <div  v-for="(item) in this.notifications.personalNotifications.messages.data" :key='item.index'
                class="box__item align_big-desktop-center">
                <template v-if="item.params.conversation.type == 'user'">
                  <div class="box__avatar box__avatar_quaternary">
                    <img v-if="item.author.avatar" :src="item.author.avatar" alt="" />
                    <img v-else src="@/assets/images/avatar-default.png" alt="Start-Up World" />
                  </div>
                </template>

                <template v-if="item.params.conversation.type == 'company'">
                  <div class="box__company__logo box__avatar_quaternary">
                    <img v-if="item.params.conversation.company.companylogo" :src="item.params.conversation.company.companylogo.link" alt="" />
                    <img v-else src="@/assets/images/no-logo.png" alt="Start-Up World" />
                  </div>
                </template>

                <div class="box__main">
                  <div class="box__header" v-if="item.params.conversation.type == 'user'">
                    <div class="wysiwyg wysiwyg_secondary mb_0">
                      <router-link :to="{name: 'profile', params: { id: item.author.id}}">
                        <h3 :class="{'read_msg': item.params.conversation.unread == 0 }">{{item.author.name}}</h3>
                      </router-link>
                      <ul>
                        <li v-if="item.author.position">{{item.author.position}}</li>
                        <li v-if="item.author.company_name">
                          <router-link :to="{name: 'company_profile', params: { id: item.author.company_id}}">
                            {{item.author.company_name}}
                          </router-link>
                        </li>
                        <li>{{item.author.location}}</li>
                      </ul>
                    </div>
                  </div>
                  <div class="box__header" v-if="item.params.conversation.type == 'company'">
                    <div class="wysiwyg wysiwyg_secondary mb_0">
                      <router-link :to="{name: 'company_profile', params: { id: item.params.conversation.company.id}}">
                        <h3>{{item.params.conversation.company.companyname}}</h3>
                      </router-link>
                      <ul>
                        <template v-if="item.params.conversation.company.companyindustry">
                          <li v-for="industry in item.params.conversation.company.companyindustry" :key="industry.id">{{industry.value}}</li>
                        </template>
                        <li>{{item.params.conversation.company.companycountry}}, {{item.params.conversation.company.companycity}}</li>
                      </ul>
                    </div>
                  </div>
                  <div class="box__content">
                    <div class="btn-group">
                      <div v-if="item.params.conversation.unread > 0" class="btn-group__btn btn-group__btn_secondary">
                        <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 3C3.55109 3 3.36032 3.07902 3.21967 3.21967C3.07902 3.36032 3 3.55109 3 3.75V13.9393L4.71967 12.2197C4.86032 12.079 5.05109 12 5.25 12H14.25C14.4489 12 14.6397 11.921 14.7803 11.7803C14.921 11.6397 15 11.4489 15 11.25V3.75C15 3.55109 14.921 3.36032 14.7803 3.21967C14.6397 3.07902 14.4489 3 14.25 3H3.75ZM2.15901 2.15901C2.58097 1.73705 3.15326 1.5 3.75 1.5H14.25C14.8467 1.5 15.419 1.73705 15.841 2.15901C16.2629 2.58097 16.5 3.15326 16.5 3.75V11.25C16.5 11.8467 16.2629 12.419 15.841 12.841C15.419 13.2629 14.8467 13.5 14.25 13.5H5.56066L2.78033 16.2803C2.56583 16.4948 2.24324 16.559 1.96299 16.4429C1.68273 16.3268 1.5 16.0533 1.5 15.75V3.75C1.5 3.15326 1.73705 2.58097 2.15901 2.15901Z" fill="#00A961"></path>
                        </svg>
                        <span class="btn__text">Unread messages ({{item.params.conversation.unread}})</span>
                      </div>
                      <div class="btn-group__btn">
                        <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25ZM0.75 9C0.75 4.44365 4.44365 0.75 9 0.75C13.5563 0.75 17.25 4.44365 17.25 9C17.25 13.5563 13.5563 17.25 9 17.25C4.44365 17.25 0.75 13.5563 0.75 9Z" fill="#5D6167"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M9 3.75C9.41421 3.75 9.75 4.08579 9.75 4.5V8.53647L12.3354 9.82918C12.7059 10.0144 12.8561 10.4649 12.6708 10.8354C12.4856 11.2059 12.0351 11.3561 11.6646 11.1708L8.66459 9.67082C8.4105 9.54378 8.25 9.28408 8.25 9V4.5C8.25 4.08579 8.58579 3.75 9 3.75Z" fill="#5D6167"/>
                        </svg>
                        <span class="btn__text">{{item.date}}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="box__actions ml-auto">
                  <div class="btn-group btn-group_secondary">
                    <template v-if="item.params.conversation.type == 'user'">
                      <router-link class="btn btn_tertiary" target="_blank"
                      :to="{ name: 'crmUser', params: { id: item.crm_id }}"
                      v-if="item.in_my_crm">
                      <span class="btn__text">Open CRM Card</span>
                      <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 5.25C3.55109 5.25 3.36032 5.32902 3.21967 5.46967C3.07902 5.61032 3 5.80109 3 6V14.25C3 14.4489 3.07902 14.6397 3.21967 14.7803C3.36032 14.921 3.55109 15 3.75 15H12C12.1989 15 12.3897 14.921 12.5303 14.7803C12.671 14.6397 12.75 14.4489 12.75 14.25V9.75C12.75 9.33579 13.0858 9 13.5 9C13.9142 9 14.25 9.33579 14.25 9.75V14.25C14.25 14.8467 14.0129 15.419 13.591 15.841C13.169 16.2629 12.5967 16.5 12 16.5H3.75C3.15326 16.5 2.58097 16.2629 2.15901 15.841C1.73705 15.419 1.5 14.8467 1.5 14.25V6C1.5 5.40326 1.73705 4.83097 2.15901 4.40901C2.58097 3.98705 3.15326 3.75 3.75 3.75H8.25C8.66421 3.75 9 4.08579 9 4.5C9 4.91421 8.66421 5.25 8.25 5.25H3.75Z" fill="#00A961"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 2.25C10.5 1.83579 10.8358 1.5 11.25 1.5H15.75C16.1642 1.5 16.5 1.83579 16.5 2.25V6.75C16.5 7.16421 16.1642 7.5 15.75 7.5C15.3358 7.5 15 7.16421 15 6.75V3H11.25C10.8358 3 10.5 2.66421 10.5 2.25Z" fill="#00A961"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2803 1.71967C16.5732 2.01256 16.5732 2.48744 16.2803 2.78033L8.03033 11.0303C7.73744 11.3232 7.26256 11.3232 6.96967 11.0303C6.67678 10.7374 6.67678 10.2626 6.96967 9.96967L15.2197 1.71967C15.5126 1.42678 15.9874 1.42678 16.2803 1.71967Z" fill="#00A961"/>
                      </svg>
                    </router-link>
                    <a class="btn btn_tertiary" href="#"
                    v-if="!item.in_my_crm && !item.params.conversation.author.private && !item.params.conversation.author.private_message"
                    @click.prevent="addToCRM(item)">
                    <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M9 3C9.41421 3 9.75 3.33579 9.75 3.75V14.25C9.75 14.6642 9.41421 15 9 15C8.58579 15 8.25 14.6642 8.25 14.25V3.75C8.25 3.33579 8.58579 3 9 3Z" fill="white"></path>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M3 9C3 8.58579 3.33579 8.25 3.75 8.25H14.25C14.6642 8.25 15 8.58579 15 9C15 9.41421 14.6642 9.75 14.25 9.75H3.75C3.33579 9.75 3 9.41421 3 9Z" fill="white"></path>
                    </svg>
                    <span class="btn__text">Add to CRM</span>
                  </a>
                </template>
                <template v-if="item.params.conversation.type == 'company'">
                  <router-link class="btn btn_tertiary" target="_blank"
                  :to="{ name: 'crmUser', params: { id: item.crm_id }}"
                  v-if="item.in_my_crm">
                  <span class="btn__text">Open CRM Card</span>
                  <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 5.25C3.55109 5.25 3.36032 5.32902 3.21967 5.46967C3.07902 5.61032 3 5.80109 3 6V14.25C3 14.4489 3.07902 14.6397 3.21967 14.7803C3.36032 14.921 3.55109 15 3.75 15H12C12.1989 15 12.3897 14.921 12.5303 14.7803C12.671 14.6397 12.75 14.4489 12.75 14.25V9.75C12.75 9.33579 13.0858 9 13.5 9C13.9142 9 14.25 9.33579 14.25 9.75V14.25C14.25 14.8467 14.0129 15.419 13.591 15.841C13.169 16.2629 12.5967 16.5 12 16.5H3.75C3.15326 16.5 2.58097 16.2629 2.15901 15.841C1.73705 15.419 1.5 14.8467 1.5 14.25V6C1.5 5.40326 1.73705 4.83097 2.15901 4.40901C2.58097 3.98705 3.15326 3.75 3.75 3.75H8.25C8.66421 3.75 9 4.08579 9 4.5C9 4.91421 8.66421 5.25 8.25 5.25H3.75Z" fill="#00A961"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 2.25C10.5 1.83579 10.8358 1.5 11.25 1.5H15.75C16.1642 1.5 16.5 1.83579 16.5 2.25V6.75C16.5 7.16421 16.1642 7.5 15.75 7.5C15.3358 7.5 15 7.16421 15 6.75V3H11.25C10.8358 3 10.5 2.66421 10.5 2.25Z" fill="#00A961"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2803 1.71967C16.5732 2.01256 16.5732 2.48744 16.2803 2.78033L8.03033 11.0303C7.73744 11.3232 7.26256 11.3232 6.96967 11.0303C6.67678 10.7374 6.67678 10.2626 6.96967 9.96967L15.2197 1.71967C15.5126 1.42678 15.9874 1.42678 16.2803 1.71967Z" fill="#00A961"/>
                  </svg>
                </router-link>
                <a class="btn btn_tertiary" href="#"
                v-if="!item.in_my_crm"
                @click.prevent="addToCRM(item)">
                <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M9 3C9.41421 3 9.75 3.33579 9.75 3.75V14.25C9.75 14.6642 9.41421 15 9 15C8.58579 15 8.25 14.6642 8.25 14.25V3.75C8.25 3.33579 8.58579 3 9 3Z" fill="white"></path>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M3 9C3 8.58579 3.33579 8.25 3.75 8.25H14.25C14.6642 8.25 15 8.58579 15 9C15 9.41421 14.6642 9.75 14.25 9.75H3.75C3.33579 9.75 3 9.41421 3 9Z" fill="white"></path>
                </svg>
                <span class="btn__text">Add to CRM</span>
              </a>
            </template>

            <a class="btn btn_secondary" href="#"
            @click.prevent="openMsg(item)">
            <span class="btn__text">Open message</span>
          </a>
        </div>
      </div>
    </div>


  </div>
</div>
</div>
</div>

</div>
</div>
</div>
</div>
</template>

<script>
import store from '@/store'
import ToolTip from '@/components/ToolTip'

export default {
  name: "NotificationBox",
    components: {
    ToolTip,
  },
  data() {
    return {
      accordion: {
        forumResponse: false,
        accessResponse: false,
        messages: false
      }
    }
  },
  beforeMount() {
    this.getNotifications();
  },
  methods: {
    getNotifications: function() {
      store.dispatch('GET_NOTIFICATIONS_PERSONAL');
    },
    goToTopic(note) {
      store.commit('SET_COMMENT_TO_HIGHLIGHT', note.params.comment_id);
      store.dispatch('SET_NOTIFICATION_VIEWED', {id: note.id, type: 'personal'});
      this.$router.push({name: "post", params: {id: note.params.post_id}})
    },
    setViewed(note) {
      store.dispatch('SET_NOTIFICATION_VIEWED', {id: note.id, type: 'personal'});
    },
    openMsg(item) {
      if (item.author.in_my_team && item.params.conversation.type === 'user') {
        store.dispatch('CHAT_USER_CONVERSATION_OPEN', item.author.id);
        return;
      }
      store.dispatch('OPEN_CONVERSATION', item.params.conversation);
      store.dispatch('SET_NOTIFICATION_VIEWED', {id: item.id, type: 'personal'});
    },
    async addToCRM(item) {
      let message = item.params.conversation;
      if (message.type == 'user') await store.dispatch('ADD_CONTACT_TO_CRM', {
        contact_id: message.author.id,
        type: 'user',
        notification_id: item.id
      });
      if (message.type == 'company') await store.dispatch('ADD_CONTACT_TO_CRM', {
        contact_id: message.to_company_id,
        type: 'company',
        notification_id: item.id
      });
      store.dispatch('GET_NOTIFICATIONS_PERSONAL');
    },
  },
  computed: {
    notifications: () => store.state.notifications,
  }
}
</script>
<style>
.box .box__item .box__avatar img {
  max-height: 100%;
}

h3.read_msg {
  font-weight: normal;
}

.box__header .wysiwyg.wysiwyg_secondary.mb_0 ul li:first-child:before {
  content: unset;
}

.box .box__item {
  position: relative;
}

.box-review {
  position: absolute;
  right: 0;
}
</style>